import React, { Component } from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"

export default class contact extends Component {
  render() {
    return (
      <Layout>
        <Hero
          title={`Reach out to us`}
          text={`We're always in for a good conversation. Whether it's for your exciting idea or just for fun sake. Fill in the form below, so we can reach out to you!`}
        />
      </Layout>
    )
  }
}
